import { RootState } from "./../store/index";
import { IStudiesStats } from "./../interfaces/index";
import { createSlice } from "@reduxjs/toolkit";

const initialState: IStudiesStats = {
  approved: 0,
  complete: 0,
  pending: 0,
  total: 0,
  unapproved: 0,
};

export const studyStatsSlice = createSlice({
  name: "studyStats",
  initialState,
  reducers: {
    updateStudyStats: (state, action: { payload: IStudiesStats }) => {
      state.approved = action.payload.approved;
      state.complete = action.payload.complete;
      state.pending = action.payload.pending;
      state.total = action.payload.total;
      state.unapproved = action.payload.unapproved;
    },
  },
});

export const { updateStudyStats } = studyStatsSlice.actions;

// select study stats
export const studyStats = (state: RootState) => state.studyStats;

export default studyStatsSlice.reducer;
