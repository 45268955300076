import { RootState } from "./../store/index";
import { createSlice } from "@reduxjs/toolkit";

export interface Schedule {
  UserUserId: string | null;
  createdAt: string;
  day: string;
  end: string;
  id: number;
  scheduleId: string;
  start: string;
  timezone: string;
  updatedAt: string;
  userId: string;
}

export interface UserBankDetail {
  bankCode: string;
  bankName: string;
  bankNumber: string;
  bankUser: string;
}

export interface IUserObject {
  about?: string;
  address?: string;
  country?: string;
  captureCount?: number;
  email: string;
  experience?: string;
  firstName: string;
  lastName: string;
  org?: string;
  phoneNumber: string;
  qualification?: string;
  specialites?: string;
  state?: string;
  sosSp?: string;
  sosLp?: string;
  referralCode?: string;
  schedule?: Schedule[];
  status?: string;
  verified?: boolean;
  userId: string;
  workingHours?: string;
  website?: string;
  yoe?: string;
  QualificationDocs?: IDocument[];
}

interface IDocument {
  createdAt: string;
  documentUrl: string;
  id: number;
  name: string;
  updatedAt: string;
  userId: string;
}

export type IUserFullDetails = IUserObject & UserBankDetail;

interface IState {
  authenticated: boolean;
  token: string;
  user: IUserObject;
}

const initialRadiologistUserState: IState = {
  authenticated: false,
  token: "",
  user: {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
};

// Radiologist user object state
export const radiologistUserSlice = createSlice({
  name: "radiologistUser",
  initialState: initialRadiologistUserState,
  reducers: {
    // This updates the radiologist token
    updateRadiologistToken: (state, action) => {
      state.token = action.payload;
    },

    // Updates radiologist profile
    updateRadiologistProfile: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { updateRadiologistProfile, updateRadiologistToken } =
  radiologistUserSlice.actions;

// select radiologist token
export const radiologistToken = (state: RootState) =>
  state.radiologistUser.token;

// select radiologist user object
export const radiologistUserObject = (state: RootState) =>
  state.radiologistUser.user;

export default radiologistUserSlice.reducer;
