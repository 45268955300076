import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import App from "./App";
import { Loading } from "./components/Loader/Index";
import { API_DOMAIN } from "./utils/api-domain";

export const ElementContainer = () => {
  const [key, setkey] = useState("");
  const getKey = () => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    // let stripePromise = null;

    fetch(`${API_DOMAIN}/api/keys`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // stripePromise = loadStripe(result.data?.stripePk);
        setkey(result.data?.stripePk);
      })
      .catch((error) => {
        console.log("error", error);
        getKey();
      });
  };

  useEffect(() => {
    getKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const stripePromise = loadStripe(PRODUCTION_PUBLISHABLE_KEY);
  if (key) {
    const stripePromise = loadStripe(key);
    return (
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    );
  }
  return (
    <div>
      <Loading styles={{ marginTop: "4em" }} />
    </div>
  );
};
