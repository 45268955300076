import { CSSProperties, FC } from "react";
import { StyledLoading } from "./Styles";
import { LoaderIcon } from "react-hot-toast";

export enum Sizes {
  xxs = "1.2rem",
  xs = "1.8rem",
  sm = "2.5rem",
  md = "3rem",
  lg = "4rem",
  xl = "5rem",
}

export interface ILoaderProps {
  size?: Sizes;
  className?: string;
  styles?: CSSProperties;
  loaderStyles?: CSSProperties;
}

export const Loading: FC<ILoaderProps> = ({
  size = Sizes.xl,
  className = "",
  styles = {},
  loaderStyles = {},
}) => {
  if (!loaderStyles.width) {
    loaderStyles.width = size;
    loaderStyles.height = size;
  }

  return (
    <StyledLoading className={className} style={styles}>
      <LoaderIcon style={loaderStyles} />
    </StyledLoading>
  );
};
