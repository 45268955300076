import { createGlobalStyle } from "styled-components";

const ButtonStyles = createGlobalStyle`
    .btn {
        display: inline-block;
        cursor: pointer;

        &__gen {
            border-radius: var(--border-rad-xs);
            padding: 1.6rem 1.5rem;
        }

        &__large {
            width: 100%;
        }

        &__outline {
            background-color: transparent;
            border: 0.1rem solid;
        }

        &__primary {
            background-color: var(--primary-1);
            color: var(--white);
            padding: 1.6rem 2.4rem;
        }

        &__links {
            /*  */
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            opacity: 0.8;
        }
    }
`;

export default ButtonStyles;
