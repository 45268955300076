import { RootState } from "./../store/index";
import { createSlice } from "@reduxjs/toolkit";

export interface IDeptObject {
  orgId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  orgName?: string;
  website?: string;
  state?: string;
  country?: string;
  emailVerifiedAt?: any;
  referralCode?: string;
  status?: string;
  balance?: string;
}

interface IState {
  authenticated: boolean;
  token: string;
  user: IDeptObject;
}

const initialRadiologyDeptState: IState = {
  authenticated: false,
  token: "",
  // User object of radiology dept
  user: {
    orgId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    emailVerifiedAt: "",
  },
};

// Radiology department slice.
export const radiologyDeptSlice = createSlice({
  name: "radiologyDept",
  initialState: initialRadiologyDeptState,
  reducers: {
    // Changes the token of the radiology department
    updateDeptToken: (state, action) => {
      state.token = action.payload;
    },

    // Updates the user oibject of the radiology department
    updateDeptUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Selectors
export const radiologyDeptToken = (state: RootState) =>
  state.radiologyDept.token;
export const radiologyDeptUserObject = (state: RootState) =>
  state.radiologyDept.user;

// Actions
export const { updateDeptUser, updateDeptToken } = radiologyDeptSlice.actions;

export default radiologyDeptSlice.reducer;
