import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "./iframe.css";
// import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { PRODUCTION_PUBLISHABLE_KEY, PUBLISHABLE_KEY } from "./utils/consts";
// import { getCookie } from "./utils/cookies";
import { ElementContainer } from "./Element";
import { Offline, Online } from "react-detect-offline";
import { NoNetworkPage } from "./pages/no-network/NoNetwork";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    {/* Provide redux store to react */}
    <Provider store={store}>
      <Online>
        <ElementContainer />
      </Online>
      <Offline>
        <BrowserRouter>
          <NoNetworkPage />
        </BrowserRouter>
      </Offline>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
