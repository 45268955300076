import { FC, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HomeLogo } from "../HomeLogo";
import { links } from "./Links";
import { NavAuth } from "./NavAuth";
import { GiHamburgerMenu } from "react-icons/gi";
import { StyledNav } from "./styles";

export const Navbar: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const navContainerRef = useRef<HTMLDivElement | null>(null);
  const toggleBtnRef = useRef<HTMLButtonElement | null>(null);

  const closeNavCont = () => {
    let navCont = navContainerRef.current!;
    navCont.classList.remove("active");
  };

  const toggleNavCont = () => {
    let navCont = navContainerRef.current!;

    let opened = navCont.classList.contains("active");

    opened ? closeNavCont() : navCont.classList.add("active");
  };

  useEffect(() => {
    closeNavCont();
  }, [location.pathname]);

  useEffect(() => {
    let hash = history.location.hash;
    if (!hash) return;

    document.getElementById(hash.slice(1))?.scrollIntoView();
    closeNavCont();
  }, [history.location.hash]);

  return (
    <StyledNav>
      <HomeLogo className="nav__home" />
      <button
        className="nav__toggle"
        ref={toggleBtnRef}
        onClick={toggleNavCont}
      >
        <GiHamburgerMenu />
      </button>

      <div className="nav__items-cont" ref={navContainerRef}>
        <ul className="nav__lists">
          {links.map(({ href, text }) => (
            <li key={href} className="nav__list">
              <Link to={href} className={`nav__links nav__links-path`}>
                {text}
              </Link>
              {/* <button
                                className={`nav__links nav__links-path`}
                                onClick={() => {
                                    const element =
                                        document.getElementById(href);
                                    if (element) {
                                        element.scrollIntoView();
                                    }
                                }}
                            >
                                {text}
                            </button> */}
            </li>
          ))}
        </ul>
        <NavAuth />
      </div>
    </StyledNav>
  );
};
