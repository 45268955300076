export const supports = [
  // {
  //     href: "faqs",
  //     text: "FAQs",
  // },
  {
    href: "/terms",
    text: "Terms of Service",
  },
  {
    href: "/policy",
    text: "Privacy Policy",
  },
];
