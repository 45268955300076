import { FC, lazy, Suspense } from "react";
import {
  Route,
  // BrowserRouter,
  Switch,
  useLocation,
} from "react-router-dom";
import { Footer } from "./components/Footer/Index";
import { Loading } from "./components/Loader/Index";
import { Navbar } from "./components/Navbar/Index";
import { pathWithGeneralLayout } from "./constants";
// import { PaginationExample } from "./components/Pagination/Example";
const HomePage = lazy(() => import("./pages/home"));
const Onboarding = lazy(() => import("./pages/onboard"));
const RadiologistAccount = lazy(() => import("./pages/radiologist-account"));
const RadiologistDashboard = lazy(
  () => import("./pages/radiologist-dashboard")
);
const RadiologistTransactionHistory = lazy(
  () => import("./pages/radiologist-transaction-history")
);
const RadiologistSchedule = lazy(() => import("./pages/radiologist-schedule"));
const RadiologistPrices = lazy(() => import("./pages/radiologist-prices"));
// const RadiologistMessages = lazy(() => import("./pages/radiologist-messages"));
const RadiologistStudies = lazy(() => import("./pages/radiologist-studies"));
const DepStep1 = lazy(() => import("./pages/radiologist/dep-step-1"));
const DepStep2 = lazy(() => import("./pages/radiologist/dep-step-2"));
const DeptStep3 = lazy(() => import("./pages/radiologist/dep-step-3"));
const DeptStep3Add = lazy(
  () => import("./pages/radiologist/dep-step-3/DeptStep3Add")
);
const DeptStep4 = lazy(() => import("./pages/radiologist/dep-step-4"));
const DeptStep5 = lazy(() => import("./pages/radiologist/dep-step-5"));
const NoDepStep1 = lazy(() => import("./pages/radiologist/no-dep-step-1"));
const NoDepStep2 = lazy(() => import("./pages/radiologist/no-dep-step-2"));
const RegisterRadiologist = lazy(() => import("./pages/radiologist/register"));
const UserTypePage = lazy(() => import("./pages/radiologist/user-type"));
const RadiologyAccount = lazy(() => import("./pages/radiology-account"));
const RadiologyDashboard = lazy(() => import("./pages/radiology-dashboard"));
const RadiologyTransactionHistory = lazy(
  () => import("./pages/radiology-transaction-history")
);
const RadiologyStudies = lazy(() => import("./pages/radiology-studies"));
const RadiologistDeptStep1 = lazy(
  () => import("../src/pages/radiology-department/Step-1/Index")
);
const RadiologistDeptStep2 = lazy(
  () => import("../src/pages/radiology-department/Step-2/Index")
);
const RadiologistDeptStep3 = lazy(
  () => import("../src/pages/radiology-department/Step-3/Index")
);
const RadiologistDeptStep4 = lazy(
  () => import("../src/pages/radiology-department/Step-4/Index")
);
const RadiologistDeptStep5 = lazy(
  () => import("../src/pages/radiology-department/Step-5/Index")
);
// const RadiologyMessages = lazy(() => import("../src/pages/radiology-messages"));
const RadiologyRadiologists = lazy(
  () => import("../src/pages/radiology-radiologists")
);

const ProtectedRouteForDept = lazy(() =>
  import("./protected-route").then(({ ProtectedRouteForDept }) => ({
    default: ProtectedRouteForDept,
  }))
);

const ProtectedRouteForRadiologist = lazy(() =>
  import("./protected-route").then(({ ProtectedRouteForRadiologist }) => ({
    default: ProtectedRouteForRadiologist,
  }))
);

const Login = lazy(() => import("./pages/login"));
const UploadExam = lazy(() => import("./pages/upload-exam"));
const RadiologyFacilities = lazy(() => import("./pages/radiology-facilities"));
const RadiologyRadiologist = lazy(
  () => import("./pages/radiology-radiologist")
);
const RadiologyRadiologistsReviews = lazy(
  () => import("./pages/radiology-reviews")
);
const StudyForRadiology = lazy(() => import("./pages/study-for-radiology"));
const StudyForRadiologist = lazy(() => import("./pages/study-for-radiologist"));
const PdfViewer = lazy(() => import("./pages/pdf-viewer/pdf-viewer"));
const VerifyEmail = lazy(() => import("./pages/verify/email/index"));
const ForgotPassword = lazy(() => import("./pages/password/forgot/index"));
const ResetPassword = lazy(() => import("./pages/password/reset/index"));
const Terms = lazy(() => import("./pages/terms/index"));
const Policy = lazy(() => import("./pages/policy/index"));

export const Pages: FC = () => {
  const { pathname } = useLocation();

  return (
    <>
      <>
        <Suspense fallback={<Loading styles={{ marginTop: "4em" }} />}>
          <div id="google_translate_element"></div>

          {pathWithGeneralLayout.includes(pathname) && <Navbar />}
          <Switch>
            <Route exact path="/" render={() => <HomePage />} />
            <Route exact path="/terms" render={() => <Terms />} />
            <Route exact path="/policy" render={() => <Policy />} />
            <Route exact path="/verify/email" render={() => <VerifyEmail />} />

            <Route
              exact
              path="/password/forgot"
              render={() => <ForgotPassword />}
            />
            <Route
              exact
              path="/password/change"
              render={() => <ResetPassword />}
            />
            <Route exact path="/register" render={() => <Onboarding />} />

            {/* Routes with /radiologist is for radiologist */}
            <Route
              exact
              path="/radiologist/user-type"
              render={() => <UserTypePage />}
            />
            <Route
              exact
              path="/radiologist/register"
              render={() => <RegisterRadiologist />}
            />
            <ProtectedRouteForDept
              exact
              path="/add-specialist"
              render={() => <RegisterRadiologist />}
              authenticationPath="/login"
            />
            {/* ragistration step 1 (radiologist in a department) */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/register/dep-1"
              render={() => <DepStep1 />}
              authenticationPath="/login"
              // redirectPath=""
            />
            {/* ragistration step 2 (radiologist in a department) */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/register/dep-2"
              render={() => <DepStep2 />}
              authenticationPath="/login"
            />

            {/* registration step 3 (invite radiologist) */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/register/dep-3"
              render={() => <DeptStep3 />}
              authenticationPath="/login"
            />

            {/* registration step 3 (form to invite radiologist) */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/register/dep-3/add"
              render={() => <DeptStep3Add />}
              authenticationPath="/login"
            />

            {/* registration step 4 (invite colleagues) */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/register/dep-4"
              render={() => <DeptStep4 />}
              authenticationPath="/login"
            />

            {/*registration step 5 (success) */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/register/dep-5"
              render={() => <DeptStep5 />}
              authenticationPath="/login"
            />

            {/* ragistration step 1 (radiologist not in a department) */}
            <Route
              exact
              path="/radiologist/register/no-dep-1"
              render={() => <NoDepStep1 />}
            />
            {/* ragistration step 2 (radiologist not in a department) */}
            <Route
              exact
              path="/radiologist/register/no-dep-2"
              render={() => <NoDepStep2 />}
            />

            {/* Radiologist dashboard - Home */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/dashboard"
              render={() => <RadiologistDashboard />}
              authenticationPath="/login"
            />

            {/* Radiologist Transaction History */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/transaction-history"
              render={() => <RadiologistTransactionHistory />}
              authenticationPath="/login"
            />

            {/* Radiologist Schedule */}
            <ProtectedRouteForRadiologist
              path="/radiologist/schedule"
              render={() => <RadiologistSchedule />}
              authenticationPath="/login"
            />

            {/* Radiologist Prices */}
            <ProtectedRouteForRadiologist
              path="/radiologist/fees"
              render={() => <RadiologistPrices />}
              authenticationPath="/login"
            />

            {/* Radiologist dashboard - Studies */}
            <ProtectedRouteForRadiologist
              exact
              path="/radiologist/studies"
              render={() => <RadiologistStudies />}
              authenticationPath="/login"
            />

            {/* Radiologist dashboard - Messages */}
            {/* !REMOVE RADIOLOGIST MESSAGES FOR NW */}
            {/* <ProtectedRouteForRadiologist
                            exact
                            path="/radiologist/messages"
                            render={() => <RadiologistMessages />}
                            authenticationPath="/login"
                        /> */}

            {/* Radiologist dashboard - Account */}
            <ProtectedRouteForRadiologist
              // exact
              path="/radiologist/account"
              render={() => <RadiologistAccount />}
              authenticationPath="/login"
            />
            {/* View a study - Radiology department */}
            <ProtectedRouteForRadiologist
              path="/radiologist/study/:id"
              render={() => <StudyForRadiologist />}
              authenticationPath="/login"
            />

            {/* Routes with /radiology is for radiology department */}

            {/* initial registration */}
            <Route
              exact
              path="/radiology/register"
              render={() => <RadiologistDeptStep1 />}
            />

            {/* invite radiologist */}
            <ProtectedRouteForDept
              exact
              path="/radiology/register/step-2"
              render={() => <RadiologistDeptStep2 />}
              authenticationPath="/login"
            />

            {/* form to invite radiologist */}
            <ProtectedRouteForDept
              exact
              path="/radiology/register/step-3"
              render={() => <RadiologistDeptStep3 />}
              authenticationPath="/login"
            />

            {/* form to add diagnostic center */}
            <ProtectedRouteForDept
              exact
              path="/radiology/register/step-4"
              render={() => <RadiologistDeptStep4 />}
              authenticationPath="/login"
            />

            {/* success */}
            <ProtectedRouteForDept
              exact
              path="/radiology/register/step-5"
              render={() => <RadiologistDeptStep5 />}
              authenticationPath="/login"
            />

            {/* testing pagination */}
            {/* <Route
                            exact
                            path="/pagination"
                            render={() => <PaginationExample />}
                        /> */}

            {/* Login */}
            <Route exact path="/login" render={() => <Login />} />

            {/* Radiology dashboard - Home */}
            <ProtectedRouteForDept
              exact
              path="/radiology/dashboard"
              render={() => <RadiologyDashboard />}
              authenticationPath="/login"
            />

            {/* Radiology transaction History  */}
            <ProtectedRouteForDept
              exact
              path="/radiology/transaction-history"
              render={() => <RadiologyTransactionHistory />}
              authenticationPath="/login"
            />

            {/* Radiology transaction History  */}
            <ProtectedRouteForDept
              exact
              path="/radiology/studies"
              render={() => <RadiologyStudies />}
              authenticationPath="/login"
            />

            {/* Radiology facilities */}
            <ProtectedRouteForDept
              path="/radiology/facilities"
              render={() => <RadiologyFacilities />}
              authenticationPath="/login"
            />

            {/* Radiology dashboard - Radiologists */}
            <ProtectedRouteForDept
              exact
              path="/radiology/radiologists"
              render={() => <RadiologyRadiologists />}
              authenticationPath="/login"
            />

            {/* Radiology dashboard - Radiologist */}
            <ProtectedRouteForDept
              exact
              path="/radiology/radiologists/:id"
              render={() => <RadiologyRadiologist />}
              authenticationPath="/login"
            />

            {/* Radiology dashboard - Radiologist reviews */}
            <ProtectedRouteForDept
              exact
              path="/radiology/radiologists/:id/reviews"
              render={() => <RadiologyRadiologistsReviews />}
              authenticationPath="/login"
            />

            {/* View a study - Radiology department */}
            <ProtectedRouteForDept
              exact
              path="/radiology/study/:id"
              render={() => <StudyForRadiology />}
              authenticationPath="/login"
            />

            {/* Radiology dashboard - Messages */}
            {/* !COMMENT OUT RADIOLOGY MESSAGES, FOR NOW */}
            {/* <ProtectedRouteForDept
                            exact
                            path="/radiology/messages"
                            render={() => <RadiologyMessages />}
                            authenticationPath="/login"
                        /> */}

            {/* Radiology dashboard - Messages */}
            <ProtectedRouteForDept
              path="/radiology/account"
              render={() => <RadiologyAccount />}
              authenticationPath="/login"
            />

            {/* Radiology dept upload exam */}
            <ProtectedRouteForDept
              exact
              path="/radiology/upload-exam"
              render={() => <UploadExam />}
              authenticationPath="/login"
            />

            {/* registration step 3 (invite radiologist) */}
            <Route exact path="/view-pdf" render={() => <PdfViewer />} />
          </Switch>
          {pathWithGeneralLayout.includes(pathname) && <Footer />}
        </Suspense>
      </>
    </>
  );
};
