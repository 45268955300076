import styled from "@emotion/styled";
import { HomeLogo } from "../../components/HomeLogo";
import { ReactComponent as BadConnection } from "./../../assets/icons/bad-connection.svg";

export const NoNetworkPage = () => {
  return (
    <Wrapper>
      <div>
        <HomeLogo className="nav__home" />
      </div>
      <figure>
        <BadConnection />
        <h3>Ooops!, the socket is out</h3>
        <p>
          We noticed that your device is not connected to the internet. We are
          constantly watching it and you will come back on once the internet
          connection is back.
        </p>
        <p>
          If you are sure you have internet connection, click the button below
          to refresh
        </p>
        <button
          onClick={() => {
            window.location.reload();
          }}
        >
          Click to refresh
        </button>
      </figure>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 32px;

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    margin-top: 0px;
    color: #425466;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 560px;
    text-align: center;
    color: #425466;
    text-align: center;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  svg {
    height: 400px;
  }
  .nav__home img {
    height: 40px;
  }
  @media screen and (max-width: 1024px) {
    svg {
      height: 360px;
      max-width: 100%;
    }
    .nav__home img {
      height: 40px;
    }

    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 600px) {
    svg {
      height: 240px;
      max-width: 100%;
    }
    .nav__home img {
      height: 40px;
    }
  }

  button {
    margin: 0 auto;

    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    padding: 12px 20px;
    color: #fff;
    background-color: #4c6fff;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;
