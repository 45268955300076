import { FC } from "react";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/cookies";

export const NavAuth: FC = () => {
  // Get radiologist cookie
  const gistCookie = getCookie("gist");

  // Get department cookie
  const gyCookie = getCookie("gy");

  let authPaths = [
    {
      text: "Login",
      to: "/login",
      className: "nav__links-login",
    },
    {
      text: "Register",
      to: "/register",
      className: "nav__links-register",
    },
  ];

  return (
    <ul className="nav__lists">
      {/* Not signed in as dept or specialist */}
      {!gyCookie &&
        !gistCookie &&
        authPaths.map((auth) => (
          <li key={auth.to} className="nav__list">
            <Link
              to={auth.to}
              className={`nav__links nav__links-auth ${auth.className}`}
            >
              {auth.text}
            </Link>
          </li>
        ))}

      {/* Signed in as dept */}
      {gyCookie && (
        <li className="nav__list">
          <Link
            to={"/radiology/dashboard"}
            className={`nav__links nav__links-auth ${" nav__links - login"}`}
          >
            Centre Dashboard
          </Link>
        </li>
      )}

      {/* Signed in as specialist */}
      {gistCookie && (
        <li className="nav__list">
          <Link
            to={"/radiologist/dashboard"}
            className={`nav__links nav__links-auth ${" nav__links - login"}`}
          >
            Specialist Dashboard
          </Link>
        </li>
      )}
    </ul>
  );
};
