import styled from "styled-components";
import { devices } from "../../styles/breakpoints";

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  /* justify-contents: flex-start; */
  position: sticky;
  top: 0;
  padding: 2rem;
  background-color: var(--white);

  a {
    display: block;
  }

  .nav {
    &__home {
      color: var(--black);
      font-weight: 600;
    }

    &__toggle {
      margin-left: auto;
      border: 0.1rem solid transparent;
      font-size: 2rem;
    }

    &__items {
      &-cont {
        --nav-height: 6.7rem;
        position: absolute;
        top: var(--nav-height);
        display: flex;
        flex-direction: column;
        background: var(--white);
        left: -100%;
        width: 100%;
        height: calc(100vh - var(--nav-height));
        padding-left: 2rem;
        padding-right: 2rem;
        transition: all 0.2s ease-in-out;

        &.active {
          left: 0;
        }
      }
    }

    &__lists {
      display: flex;
      flex-direction: column;
    }

    &__links {
      margin-bottom: 2rem;

      &-path {
        color: var(--light);
      }

      &-auth {
        border: 0.1rem solid transparent;
        text-align: center;
        padding: 1.6rem 3.8rem;
        border-radius: var(--border-rad-lg);
      }

      &-login {
        background: var(--primary);
        color: var(--white);
      }

      &-register {
        padding-right: 4.9rem;
        padding-left: 4.9rem;
        background: var(--secondary);
        color: var(--primary);
        font-weight: 600;
      }
    }
  }

  @media screen and (${devices.tablet}) {
    .nav {
      &__home {
        font-size: 2rem;
      }

      &__links {
        font-size: 1.4rem;
      }
    }
  }

  @media screen and (${devices.laptop}) {
    padding-left: 4rem;
    padding-right: 4rem;

    .nav {
      &__toggle {
        display: none;
      }

      &__items {
        &-cont {
          position: static;
          height: auto;
          flex-direction: row;
        }
      }

      &__lists {
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        &:nth-child(2) {
          margin-left: auto;
        }
      }

      &__links {
        margin-bottom: 0;

        &-path {
          &:hover {
            color: var(--primary);
          }
        }

        &-auth {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          &:hover {
            opacity: 0.8;
          }
        }

        &-login {
        }

        &-register {
        }
      }
    }
  }

  @media screen and (${devices.desktop}) {
    padding-left: 10rem;
    padding-right: 10rem;
  }
`;
