import { ITransactionStats } from "../interfaces/index";
import { RootState } from "../store/index";
// import { IStudiesStats } from "../interfaces/index";
import { createSlice } from "@reduxjs/toolkit";

// const initialState: ITransactionStats = {
//     totaDebit: 0,
//     totalCredit: 0,
//     totalTranx: 0,
//     walletBalance: "",
// };

const initialState: ITransactionStats | { [key: string]: any } = {};

export const tranXstatsSlice = createSlice({
  name: "tranXStats",
  // initialState,
  initialState: {
    org: initialState,
    user: initialState,
  },
  reducers: {
    // updateTranXStats: (state, action: { payload: ITransactionStats }) => {
    //     state.totaDebit = action.payload.totaDebit;
    //     state.totalCredit = action.payload.totalCredit;
    //     state.totalTranx = action.payload.totalTranx;
    //     state.walletBalance = action.payload.walletBalance;
    // },
    updateTranXStats: (
      state,
      action: { payload: { stats: ITransactionStats; type: "org" | "user" } }
    ) => {
      let { stats, type } = action.payload;
      state[type] = stats;
    },
  },
});

export const { updateTranXStats } = tranXstatsSlice.actions;

// select tranX stats
export const tranXStats = (state: RootState) => state.tranXStats;

export default tranXstatsSlice.reducer;
