import { createGlobalStyle } from "styled-components";

const VariableStyles = createGlobalStyle`
    :root {
        --black: #000000;

        --white: #FFFFFF;
        --white-1: #e7e7ed;

        --light: #9A9EA6;
        --light-1: #425466;
        --light-2: #7a828a;

        --gray-10: #F7FAFC;
        --gray-20: #EDF2F7;
        --gray-30: #E2E8F0;

        --dark: #16192C;
        --dark-1: #2F234F;
        --dark-2: #27272E;
        --dark-3: #222;
        --dark-opacity-2: rgba(22, 25, 44, 0.2);
        --dark-opacity-25: rgba(22, 25, 44, 0.25);

        --primary: #4475F2;
        --primary-1: #3754DB;
        --primary-2: #E1E8FF;
        --primary-3: #4C6FFF;
        --primary-4: #2E5AAC;
        --primary-5: #89a7e0;

        --secondary: #E3EBFD;
        --secondary-1: #E4ECF7;

        --muted: #718096;

        --success: #66CB9F;
        --success-1: #DEFFEE;

        --fail: #cf4655;
        --fail-1: #ffeef1;

        --info-1: #68DBF2;

        --warning-1: #FFEDE3;
        --warning-2: #F7936F;

        --tertiary: #FF92AE;

        --disabled: #b1b4ba;

        --table-head: #8492A6;
        --table-text: #272833;

        /* gradients */
        --primary-gradient-1: #D665FF, #4C6FFF;


        /* fonts */
        --font-general:  'Inter', sans-serif;

        /* border-radius */
        --border-rad-xss: 0.7rem;
        --border-rad-xs: 0.8rem;
        --border-rad-md: 2.2rem;
        --border-rad-lg: 3rem;

        /* shadows */
        --shadow-sm: 0rem 0rem 0.1rem rgba(12, 26, 75, 0.24),
                     0rem 0.3rem 0.8rem -0.1rem rgba(50, 50, 71, 0.05);
        --shadow-part-1: 0rem 0rem 0.1rem rgba(12, 26, 75, 0.24);
        --shadow-part-2: 0rem 0.4rem 2rem -0.2rem rgba(50, 50, 71, 0.08);
    }
`;

export default VariableStyles;
