type CookieName = "gist" | "gy";
// Set cookie for radiologist (gist) or radiology dept (gy)
export const setCookie = (
  name: "gist" | "gy",
  value: string,
  days: number = 1
) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 86_400_000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Get cookie for radiologist (gist) or radiology dept (gy)

export const getCookie = (name: "gist" | "gy") => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

type Acc = { [key: string]: string };

export const getCookieSecond = (name: CookieName) => {
  let cookies = document.cookie.split("; ").reduce((acc: Acc, cur) => {
    let [key, val] = cur.split("=");
    acc[key] = val;
    return acc;
  }, {});

  return cookies[name];
};

/**
 * remove radiology/specialist cookie
 */
export const deleteCookie = (name: CookieName) => {
  document.cookie = `${name}=; Max-Age=-99999999; path=/;`;
};
