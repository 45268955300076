import { RootState } from "../store/index";
import { createSlice } from "@reduxjs/toolkit";

const initialState: { pKey: string } = {
  pKey: "",
};

export const pubKeySlice = createSlice({
  name: "pubKey",
  initialState,
  reducers: {
    updatePKey: (state, action: { payload: string }) => {
      state.pKey = action.payload;
    },
  },
});

export const { updatePKey } = pubKeySlice.actions;

// select study stats
export const pubKey = (state: RootState) => state.pubKey;

export default pubKeySlice.reducer;
