/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IReview } from "../components/Reviews/Review";
import {
  SuccessResponsePagination,
  SuccessResponse,
  Radiologist,
  IModality,
  IStudyImageData,
  IRadiologyDeptRequest,
  Facility,
  IStudy,
  IReport,
  IStudiesStats,
  IComment,
  WishListProfile,
  IHipaaLog,
  ISettingsResponse,
  ITransactionHistory,
  ITransactionStats,
} from "../interfaces";
import { IModalityPriceObj } from "../modules/Radiologist/ModalitiesAndPrices/interface";
import { IUploadFormInterface } from "../pages/upload-exam/upload.interface";
import { IUserFullDetails, IUserObject } from "../slices/radiologist-user";
import { API_ROUTE } from "../utils/api-domain";
import { createFacilityData } from "./types/create-facility-data";
import {
  DataAddRadiologist,
  DataRadiologistRegister,
  DataRadiologistStep1,
  DataLogin,
  DataRadiologyDeptRegister,
  DataRadiologyStep1,
} from "./types/register.types";
import { DataAddSchedule } from "./types/schedule.types";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { getCookie } from "../utils/cookies";
import toast from "react-hot-toast";

const baseQuery = fetchBaseQuery({ baseUrl: API_ROUTE });
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const accessToken: string =
      (getCookie("gist") as string) || (getCookie("gy") as string);
    if (!accessToken) {
      toast.error("Your session has expired. Please log in again");
      window.location.href = "/login";
      // return;
    }
  }
  return result;
};

export const solaceRadApi = createApi({
  // slice name we'll use to identify the reducer
  reducerPath: "solaceRadApi",

  //base url
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROUTE,
  }),

  // tags for refetching
  tagTypes: [
    "radiologistReviews",
    "wishlists",
    "transaction_history",
    "facilities",
    "full-profile",
  ],

  // list of endpoints
  endpoints: (builder) => ({
    registerRadiologist: builder.mutation<any, DataRadiologistRegister>({
      query: (data) => {
        // presence of orgId means organization is inviting a specialist
        if (data.orgId) {
          delete data.password;
          delete data.confirmPassword;
        }

        return {
          url: "user/register",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["wishlists"],
    }),
    updateRadiologistProfile: builder.mutation<any, DataRadiologistStep1>({
      query: (data) => ({
        url: `user/${data.userId}`,
        method: "PUT",
        body: data.formData,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    updateBank: builder.mutation<any, DataRadiologistStep1>({
      query: (data) => ({
        url: `user/bank`,
        method: "POST",
        body: data.formData,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    updateRadiologyProfile: builder.mutation<any, DataRadiologyStep1>({
      query: (data) => ({
        url: `org/${data.orgId}`,
        method: "PUT",
        body: data.formData,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    addSchedule: builder.mutation<any, DataAddSchedule>({
      query: (data) => ({
        url: `user/${data.userId}`,
        method: "POST",
        body: data.schedule,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    deleteSchedule: builder.mutation<
      any,
      { scheduleId: string; userId: string; accessToken: string }
    >({
      query: (data) => ({
        url: `user/${data.userId}/schedule/${data.scheduleId}`,
        method: "DELETE",
        // body: data.scheduleId,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    // Add a radiologist
    addRadiologist: builder.mutation<any, DataAddRadiologist>({
      query: (data) => ({
        url: `auth/invite/`,
        method: "POST",
        body: data,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    // Login
    // Add a radiologist
    login: builder.mutation<any, DataLogin>({
      query: (data) => ({
        url: `auth/login`,
        method: "POST",
        body: data,
      }),
    }),

    // verify email
    verifyEmail: builder.query<
      any,
      {
        verificationCode: string;
        type: string;
      }
    >({
      query: (data) => ({
        url: `auth/email/verify?verificationCode=${data.verificationCode}&type=${data.type}`,
      }),
    }),

    resendVerifyEmail: builder.mutation<any, { email: string; type: string }>({
      query: ({ type, email }) => ({
        url: `auth/email/verify/resend`,
        body: { type, email },
        method: "POST",
      }),
    }),

    // forgot password
    forgotpassword: builder.mutation<
      { status: string; message: string },
      { email: string; type: string }
    >({
      query: (data) => ({
        url: `auth/password/forget`,
        method: "POST",
        body: data,
      }),
    }),

    resetpassword: builder.mutation<
      SuccessResponse<any>,
      { password: string; type: string; resetCode: string }
    >({
      query: (data) => ({
        url: `auth/password/reset?resetToken=${data.resetCode}`,
        method: "POST",
        body: data,
      }),
    }),

    // Register radiology department.
    registerRadiologyDept: builder.mutation<any, DataRadiologyDeptRegister>({
      query: (data) => ({
        url: "org/register",
        method: "POST",
        body: data,
      }),
    }),

    // Register radiology department.
    createRadiologyFacility: builder.mutation<
      any,
      { data: createFacilityData; orgId: string; accessToken: string }
    >({
      query: (data) => ({
        url: `org/${data.orgId}/facility`,
        method: "POST",
        body: data.data,
        headers: { "x-auth-token": data.accessToken },
      }),
      invalidatesTags: ["facilities"],
    }),

    getRadiologyFacilites: builder.mutation<
      SuccessResponse<{
        facilities: Facility[];
        pageNo: number;
        perPage: number;
        count: number;
      }>,
      { accessToken: string; orgId: string; perPage?: number; pageNo?: number }
    >({
      query: (data) => ({
        url: `org/${data.orgId}/facility?pageNo=${data.pageNo}&perPage=${data.perPage}`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
      // invalidatesTags: ["facilities"],
    }),

    // Get single facility
    getFacility: builder.mutation<
      SuccessResponse<Facility>,
      {
        accessToken: string;
        orgId: string;
        facilityId: string;
      }
    >({
      query: (data) => ({
        url: `org/${data.orgId}/facility/${data.facilityId}`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // Update facility
    // Register radiology department.
    updateRadiologyFacility: builder.mutation<
      any,
      {
        data: createFacilityData;
        orgId: string;
        accessToken: string;
        facilityId: string;
      }
    >({
      query: (data) => ({
        url: `org/${data.orgId}/facility/${data.facilityId}`,
        method: "PUT",
        body: data.data,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // delete facility
    deleteRadiologyFacility: builder.mutation<
      SuccessResponse<{}>,
      {
        orgId: string;
        accessToken: string;
        facilityId: string;
      }
    >({
      query: (data) => ({
        url: `org/${data.orgId}/facility/${data.facilityId}`,
        method: "DELETE",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // delete study
    deleteRadiologyStudy: builder.mutation<
      SuccessResponse<{}>,
      {
        accessToken: string;
        studyId: string;
      }
    >({
      query: (data) => ({
        url: `study/${data.studyId}`,
        method: "DELETE",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getModalities: builder.mutation<
      SuccessResponse<IModality[]>,
      { accessToken: string }
    >({
      query: (data) => ({
        url: `study/modality`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getAllRadiologists: builder.query<
      SuccessResponsePagination<{
        count: number;
        pageNo: number;
        perPage: number;
        users: IUserObject[];
      }>,
      { accessToken: string; page?: number; itemsPerPage?: number }
    >({
      query: ({ accessToken, page = 1, itemsPerPage = 5 }) => ({
        url: `user/all?pageNo=${page}&perPage=${itemsPerPage}`,
        headers: { "x-auth-token": accessToken },
      }),
    }),

    getRadiologist: builder.query<
      SuccessResponse<Radiologist>,
      { accessToken: string; radiologistId: string }
    >({
      query: ({ accessToken, radiologistId }) => ({
        url: `/user/${radiologistId}`,
        headers: { "x-auth-token": accessToken },
      }),
    }),

    // Upload each study image
    uploadStudyImage: builder.mutation<
      SuccessResponse<{
        url: string;
      }>,
      IStudyImageData
    >({
      query: (data) => ({
        url: `study/org/${data.orgId}/upload`,
        method: "POST",
        body: data.formData,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // Upload each study image
    uploadStudy: builder.mutation<
      any,
      IRadiologyDeptRequest<IUploadFormInterface>
    >({
      query: (data) => ({
        url: `study/org/${data.orgId}/`,
        method: "POST",
        body: data.data,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // Add price for a modality
    addPriceForModality: builder.mutation<
      any,
      { price: string; modalityId: string; accessToken: string }
    >({
      query: (data) => ({
        url: `user/modality/fees/`,
        method: "POST",
        body: {
          price: data.price,
          modalityId: data.modalityId,
        },
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // wish lists
    // add specialist to wishlist
    addSpecialistToWishList: builder.mutation<
      SuccessResponse<any>,
      {
        accessToken: string;
        orgId: string;
        userId: string;
        choice?: string;
      }
    >({
      query: ({ accessToken, orgId, userId, choice = "" }) => ({
        url: `org/${orgId}/wishlist`,
        method: "POST",
        body: { userId, choice },
        headers: { "x-auth-token": accessToken },
      }),
      invalidatesTags: ["wishlists"],
    }),

    // get settings
    getSettings: builder.query<
      SuccessResponse<ISettingsResponse[]>,
      { accessToken: string; type: "org" | "user" }
    >({
      query: ({ accessToken, type }) => ({
        url: `/study/settings/?type=${type}`,
        headers: { "x-auth-token": accessToken },
      }),
    }),

    // add settings
    addSetting: builder.mutation<
      SuccessResponse<ISettingsResponse>,
      {
        accessToken: string;
        settingName: string;
        settingValue: string | boolean;
        settingType: number;
        settingValueType: number;
        type: string;
      }
    >({
      query: ({
        accessToken,
        settingName,
        settingValue,
        settingType,
        settingValueType,
        type,
      }) => ({
        url: `study/settings`,
        method: "POST",
        body: {
          settingName,
          settingValue,
          settingType,
          settingValueType,
          type,
        },
        headers: { "x-auth-token": accessToken },
      }),
      invalidatesTags: ["wishlists"],
    }),

    // get org wishlist
    getSpecialistWishList: builder.query<
      // SuccessResponse<WishListProfile[]>,
      SuccessResponse<{
        count: number;
        pageNo: number;
        perPage: number;
        wishes: WishListProfile[];
      }>,
      {
        accessToken: string;
        orgId: string;
        page?: number;
        itemsPerPage?: number;
      }
    >({
      query: ({ accessToken, orgId, page = 1, itemsPerPage = 20 }) => ({
        url: `org/${orgId}/wishlist?pageNo=${page}&perPage=${itemsPerPage}`,
        headers: { "x-auth-token": accessToken },
      }),
      providesTags: ["wishlists"],
    }),

    // delete org wishlist
    deleteSpecialistWishList: builder.mutation<
      SuccessResponse<any>,
      {
        accessToken: string;
        orgId: string;
        userId: string;
      }
    >({
      query: (query) => ({
        url: `org/${query.orgId}/wishlist/${query.userId}`,
        method: "DELETE",
        headers: { "x-auth-token": query.accessToken },
      }),
      invalidatesTags: ["wishlists"],
    }),

    // get reviews
    getRadiologistReviews: builder.query<
      SuccessResponse<IReview[]>,
      { accessToken: string; radiologistId: string }
    >({
      query: ({ accessToken, radiologistId }) => ({
        url: `user/${radiologistId}/reviews`,
        headers: { "x-auth-token": accessToken },
      }),
      providesTags: ["radiologistReviews"],
    }),

    // radiologist create a review
    createRadiologyRadiologistReview: builder.mutation<
      SuccessResponse<unknown>, // use unknown because we only need the message from response
      {
        accessToken: string;
        userId: string;
        data: { review: string; rate: string; facilityId: string };
      }
    >({
      query: ({ accessToken, userId, data: body }) => ({
        url: `user/${userId}/reviews`,
        method: "POST",
        headers: { "x-auth-token": accessToken },
        body,
      }),
      invalidatesTags: ["radiologistReviews"],
    }),

    // specialist and department can comment on study
    commentOnStudy: builder.mutation<
      SuccessResponse<unknown>, // use unknown because we only need the message from response
      {
        accessToken: string;
        studyId: string;
        data: { userId?: string; orgId?: string; comment: string };
      }
    >({
      query: ({ studyId, accessToken, data: body }) => ({
        url: `study/${studyId}/comments`,
        method: "POST",
        headers: { "x-auth-token": accessToken },
        body: body.orgId
          ? {
              orgId: body.orgId,
              comment: body.comment,
            }
          : {
              userId: body.userId,
              comment: body.comment,
            },
      }),
    }),

    // specialist and department can comment on study
    getCommentsOnStudy: builder.mutation<
      SuccessResponse<IComment[]>, // use unknown because we only need the message from response
      {
        accessToken: string;
        studyId: string;
      }
    >({
      query: ({ studyId, accessToken }) => ({
        url: `study/${studyId}/comments`,
        method: "GET",
        headers: { "x-auth-token": accessToken },
      }),
    }),

    // hipaa log
    getHipaaLog: builder.mutation<
      SuccessResponse<{ count: number; logs: IHipaaLog[] }>,
      {
        accessToken: string;
        studyId: string;
      }
    >({
      query: ({ accessToken, studyId }) => ({
        url: `study/${studyId}/logs`,
        method: "GET",
        headers: { "x-auth-token": accessToken },
      }),
    }),

    getOrgStudies: builder.mutation<
      SuccessResponse<{
        studies: IStudy[];
        pageNo: number;
        perPage: number;
        count: number;
      }>,
      {
        accessToken: string;
        orgId: string;
        perPage?: number;
        pageNo?: number;
        status?: string;
        facilityId?: string;
        userId?: string;
        modalityId?: string;
      }
    >({
      query: (data) => ({
        url: `study/org/${data.orgId}?pageNo=${data.pageNo}&perPage=${
          data.perPage
        }${
          data.status && data.status !== "All" ? `&status=${data.status}` : ""
        }${
          data.facilityId && data.facilityId !== "All"
            ? `&facilityId=${data.facilityId}`
            : ""
        }${
          data.userId && data.userId !== "All" ? `&userId=${data.userId}` : ""
        }${
          data.modalityId && data.modalityId !== "All"
            ? `&modalityId=${data.modalityId}`
            : ""
        }`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getUserModalityPrice: builder.query<
      SuccessResponse<IModalityPriceObj[]>,
      {
        accessToken: string;
        userId: string;
      }
    >({
      query: (data) => ({
        url: `user/${data.userId}/modality/fees`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getModalityPrices: builder.query<
      SuccessResponse<IModalityPriceObj[]>,
      {
        accessToken: string;
        userId: string;
      }
    >({
      query: (data) => ({
        // url: `user/modality/fees`,
        url: `user/${data.userId}/modality/fees`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getModalitiesPrices: builder.mutation<
      SuccessResponse<IModalityPriceObj[]>,
      {
        accessToken: string;
      }
    >({
      query: (data) => ({
        url: `user/modality/fees`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getUserStudies: builder.mutation<
      SuccessResponse<{
        studies: IStudy[];
        pageNo: number;
        perPage: number;
        count: number;
      }>,
      {
        accessToken: string;
        userId: string;
        perPage?: number;
        pageNo?: number;
        status?: string;
        modalityId?: string;
      }
    >({
      query: (data) => ({
        url: `study/user/${data.userId}?pageNo=${data.pageNo}&perPage=${
          data.perPage
        }${data.status ? `&status=${data.status}` : ""}${
          data.modalityId && data.modalityId !== "All"
            ? `&modalityId=${data.modalityId}`
            : ""
        }`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    getSingleStudy: builder.mutation<
      SuccessResponse<IStudy>,
      { accessToken: string; studyId: string }
    >({
      query: (data) => ({
        url: `study/${data.studyId}`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    provideReportToStudy: builder.mutation<
      SuccessResponse<{}>,
      { accessToken: string; studyId: string; report: IReport }
    >({
      query: (data) => ({
        url: `study/${data.studyId}/review/`,
        method: "PATCH",
        body: data.report,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    updateStudyStatus: builder.mutation<
      SuccessResponse<{}>,
      {
        accessToken: string;
        studyId: string;
        status: IStudy["status"];
        disapproveComment?: string;
      }
    >({
      query: (data) => {
        let body = { status: data.status, comment: data.disapproveComment };
        if (data.status !== "2") delete body.comment;

        return {
          url: `study/${data.studyId}/`,
          method: "PATCH",
          body,
          headers: { "x-auth-token": data.accessToken },
        };
      },
    }),

    // Retrieve org study stats
    getOrgStudyStat: builder.mutation<
      SuccessResponse<IStudiesStats>,
      { accessToken: string }
    >({
      query: (data) => ({
        url: `study/org/stat`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }),

    // Retrieve user study stats
    getUserStudyStat: builder.mutation<
      SuccessResponse<IStudiesStats>,
      { accessToken: string }
    >({
      query: (data) => ({
        url: `study/user/stat`,
        method: "GET",
        headers: { "x-auth-token": data.accessToken },
      }),
    }), // Retrieve user study stats
    fundOrgWallet: builder.mutation<
      any,
      {
        accessToken: string;
        orgId: string;
        amount: number;
        paymentIntentId?: string;
      }
    >({
      query: (data) => ({
        url: `org/${data.orgId}/fund`,
        method: "POST",
        headers: { "x-auth-token": data.accessToken },
        body: !data.paymentIntentId
          ? {
              amount: data.amount,
            }
          : {
              amount: data.amount,
              paymentIntentId: data.paymentIntentId,
            },
      }),
      invalidatesTags: ["transaction_history"],
    }),

    getTransactionHistory: builder.query<
      SuccessResponsePagination<{
        tranx: ITransactionHistory[];
        count?: number;
        pageNo?: number;
        perPage?: number;
      }>,
      {
        accessToken: string;
        page?: number;
        itemsPerPage?: number;
        type: "org" | "user";
      }
    >({
      query: (params) => ({
        url: `/study/${params.type}/transactions?pageNo=${
          params.page || 1
        }&itemsPerPage=${params.itemsPerPage || 10}`,
        headers: { "x-auth-token": params.accessToken },
      }),
      providesTags: ["transaction_history"],
    }),

    getTransactionStat: builder.query<
      SuccessResponse<ITransactionStats>,
      {
        accessToken: string;
        type: "org" | "user";
      }
    >({
      query: (data) => ({
        url: `/study/${data.type}/transactions/stats`,
        headers: { "x-auth-token": data.accessToken },
      }),
      providesTags: ["transaction_history"],
    }),

    getOrgTransactionStat: builder.mutation<
      SuccessResponse<ITransactionStats>,
      {
        accessToken: string;
      }
    >({
      query: (data) => ({
        url: `/study/org/transactions/stats`,
        headers: { "x-auth-token": data.accessToken },
      }),
    }),
    getKey: builder.query<
      any,
      {
        accessToken: string;
      }
    >({
      query: (data) => ({
        url: `/keys`,
        headers: { "x-auth-token": data.accessToken },
      }),
      providesTags: ["transaction_history"],
    }),
    // delete org wishlist
    deleteDocs: builder.mutation<
      SuccessResponse<any>,
      {
        accessToken: string;
        url: string;
      }
    >({
      query: (query) => ({
        url: `/study/docs/delete`,
        method: "POST",
        body: {
          url: query.url,
        },
        headers: { "x-auth-token": query.accessToken },
      }),
    }),

    getFullUserOrDeptDetails: builder.query<
      SuccessResponse<IUserFullDetails>,
      {
        accessToken: string;
        type: "org" | "user";
        id: string;
      }
    >({
      query: (data) => ({
        url: `/${data.type}/${data.id}`,
        headers: { "x-auth-token": data.accessToken },
      }),
      providesTags: ["full-profile"],
    }),
  }),
});

// Exporting the endpoints
export const {
  useRegisterRadiologistMutation,
  useUpdateRadiologistProfileMutation,
  useAddScheduleMutation,
  useDeleteScheduleMutation,
  useAddRadiologistMutation,
  useLoginMutation,
  useVerifyEmailQuery,
  useResendVerifyEmailMutation,
  useForgotpasswordMutation,
  useResetpasswordMutation,

  useGetAllRadiologistsQuery,
  useGetRadiologistQuery,
  useRegisterRadiologyDeptMutation,

  useCreateRadiologyFacilityMutation,
  useGetRadiologyFacilitesMutation,
  useGetFacilityMutation,
  useUpdateRadiologyFacilityMutation,
  useDeleteRadiologyFacilityMutation,

  useUpdateRadiologyProfileMutation,

  useGetModalitiesMutation,
  useUploadStudyImageMutation,
  useUploadStudyMutation,
  useDeleteRadiologyStudyMutation,

  useGetRadiologistReviewsQuery,
  useCreateRadiologyRadiologistReviewMutation,

  useGetOrgStudiesMutation,
  useGetUserStudiesMutation,
  useGetSingleStudyMutation,
  useProvideReportToStudyMutation,
  useGetOrgStudyStatMutation,
  useGetUserStudyStatMutation,
  useUpdateStudyStatusMutation,
  useAddPriceForModalityMutation,

  useCommentOnStudyMutation,
  useGetCommentsOnStudyMutation,

  useGetHipaaLogMutation,

  useGetSpecialistWishListQuery,
  useAddSpecialistToWishListMutation,
  useDeleteSpecialistWishListMutation,

  // modalities
  useGetModalitiesPricesMutation,
  useGetModalityPricesQuery,
  useGetUserModalityPriceQuery,

  // setting
  useAddSettingMutation,
  useGetSettingsQuery,

  // fund
  useFundOrgWalletMutation,

  // transactions
  useGetTransactionHistoryQuery,
  useGetOrgTransactionStatMutation,
  useGetTransactionStatQuery,
  useUpdateBankMutation,

  // get publishable key
  useGetKeyQuery,

  // delete docs
  useDeleteDocsMutation,
} = solaceRadApi;
