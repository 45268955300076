import { createGlobalStyle } from "styled-components";

const BaseStyles = createGlobalStyle`
    html {
        font-size: 62.5%;
        box-sizing: border-box;
    }

    html, body {
        height: 100%;
    }

    #root {
        min-height: 100vh;
    }

    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    body {
        font-size: 1.8rem;
        line-height: 1.5;
        font-family: var(--font-general);
        color: var(--dark);
    }

    ul {
        list-style: none;
    }

    button {
        cursor: pointer;
        background: transparent;
    }

    a {
        text-decoration: none;
    }

    a,
    button {
        transition: all 0.2s linear;
        border: 0.1rem solid transparent;
        text-align: center;
    }

    img,
    svg {
        max-width: 100%;
    }

    /* headings */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 900;
    }

    h1 {
        font-size: 3.2rem;
        /* margin: 0.67em 0; */
    }

    h2 {
        font-size: 2.4rem;
        /* margin: 0.75em 0; */
    }
    
    h3 {
        font-size: 1.8rem;
        /* margin: 0.83em 0; */
    }

    h5 {
        font-size: 1.3rem;
        /* margin: 1.5em 0; */
    }

    h6 {
        font-size: 1rem;
        /* margin: 1.67em 0; */
    }

    :disabled {
        cursor: not-allowed !important;
        opacity: 1 !important;
        background-color: var(--disabled) !important;
    }
`;

export default BaseStyles;
