import { FC } from "react";
import { Link } from "react-router-dom";
import { HomeLogo } from "../HomeLogo";
// import { links } from "./links";
// import { socials } from "./socials";
import { StyledFooter } from "./styles";
import { supports } from "./utils";

export const Footer: FC = () => {
  const contacts = [
    {
      text: "+18254386368",
      span: "Call us on: ",
      href: "tel:+18254386368",
    },
    {
      text: "Connect with us on WhatsApp",
      href: "https://wa.me/+18254386368",
    },
    {
      text: "hello@solaceimaging.com",
      span: "Email us on: ",
      href: "mailto:hello@solaceimaging.com",
    },
    {
      text: "",
      span: "Address: Calgary, Alberta",
      show: null,
    },
    // Address:
  ];

  return (
    <StyledFooter>
      <header className="footer__header footer__sec flex">
        <HomeLogo
          className="footer__logo"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        />
        <p className="footer__header--text">Diagnostic reporting made easy.</p>
      </header>
      <div className="footer--cont flex">
        {/* FIXME: uncommnet when there is content to individual page */}
        <section className="footer__sec">
          <h4 className="footer__sec--head">Support</h4>
          <ul className="footer__sec--ul">
            {supports.map(({ href, text }) => (
              <li key={href} className="footer__sec--li">
                <Link to={href} className="btn footer__sec--link">
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </section>
        <section className="footer__sec" id="contact-us">
          <h4 className="footer__sec--head">Contact</h4>
          <ul>
            {contacts.map((contact) => {
              const Anchor = (
                <a href={contact.href} className="btn footer__sec--link">
                  {contact.text}
                </a>
              );
              return (
                <li
                  className="footer__sec--li"
                  key={contact.span || contact.text}
                >
                  {contact.span ? (
                    <p className="footer--text">
                      {contact.span}
                      {contact.show !== null && Anchor}
                    </p>
                  ) : (
                    Anchor
                  )}
                </li>
              );
            })}
            {/* <li className="footer__sec--li">
                            <p className="footer--text">
                                Call us on:{" "}
                                <a
                                    href="tel:+18254386368"
                                    className="btn footer__sec--link"
                                >
                                    +18254386368
                                </a>
                            </p>
                        </li>
                        <li className="footer__sec--li">
                            <a
                                href="https://wa.me/+18254386368"
                                className="btn footer__sec--link"
                            >
                                Connect with us on Whatsapp
                            </a>
                        </li>
                        <li className="footer__sec--li">
                            <a
                                href="mailto:hello@solaceimaging.com"
                                className="btn footer__sec--link"
                            >
                                Email: hello@solaceimaging.com
                            </a>
                        </li> */}
          </ul>
        </section>
      </div>
      {/* <ul className="footer__pages">
                {links.map((link) => (
                    <li key={link.text} className="footer__pages-list">
                        <Link to={link.to} className="footer__pages-link">
                            {link.text}
                        </Link>
                    </li>
                ))}
            </ul> */}

      {/* <ul className="footer__social">
                {socials.map(({ Icon, label, href }) => (
                    <li key={label} className="footer__social-list">
                        <a
                            href={href}
                            aria-label={`Our ${label}'s page`}
                            className="footer__social-link"
                        >
                            <Icon />
                        </a>
                    </li>
                ))}
            </ul> */}
    </StyledFooter>
  );
};
