import { configureStore } from "@reduxjs/toolkit";
import { solaceRadApi } from "../services";
import radiologistUserReducer from "./../slices/radiologist-user";
import radiologyDeptReducer from "../slices/radiology-dept.slice";
import studyStatReducer from "./../slices/study-stats.slice";
import tranXStatReducer from "../slices/tranXstats.slice";
import pubKeyReducer from "../slices/p-key.slice";

// Create redux store and configure redux devtools in the browser
export const store: any = configureStore({
  reducer: {
    // The Solace Imaging api endpoints
    [solaceRadApi.reducerPath]: solaceRadApi.reducer,

    // Radiologist user object
    radiologistUser: radiologistUserReducer,

    // Radiology department
    radiologyDept: radiologyDeptReducer,

    // Study stats
    studyStats: studyStatReducer,

    // Transaction stats
    tranXStats: tranXStatReducer,

    // Publishable key
    pubKey: pubKeyReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(solaceRadApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
