import styled from "styled-components";
import { devices } from "../../styles/breakpoints";

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.7rem 2rem;
  margin: 0 auto;
  /* border-top: 0.2rem solid var(--dark-opacity-2); */
  border-top: 0.05rem solid var(--dark-opacity-2);
  background-color: var(--primary-2);
  padding-bottom: 4rem;

  & > * {
    margin-bottom: 2rem;
  }

  .footer {
    &--text {
      font-size: 1.6rem;
      font-size: 1.4rem;
    }

    &--cont {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &__header {
      flex-direction: column;

      &--text {
        font-size: 1.6rem;
      }
    }

    &__logo {
      color: var(--dark-1) !important;
      font-weight: 800;
      margin-right: auto;
    }

    &__sec {
      /* text-align: center; */
      margin-bottom: 2rem;

      &--head {
        /* font-size: 1.6rem; */
        margin-bottom: 1rem;
      }

      &--link {
        font-size: 1.4rem;
        /* color: var(--dark-3); */
        color: var(--light-1);
        margin-bottom: 0.7rem;

        &:hover {
          color: var(--primary-1);
        }
      }
    }

    /* &__pages,
        &__social {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
        } */

    /* &__pages,
        &__social {
            &-link {
                color: var(--dark);
            }
        } */

    /* &__pages {
            &-list {
            }

            &-link {
                font-size: 1.6rem;
            }
        } */

    /* &__social {
            &-list {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0.225rem solid var(----dark-opacity-25);
                border-radius: 50%;
                padding: 0.3rem 0.5rem 0.2rem;
                width: 4rem;
                height: 4rem;
            }
        } */
  }

  @media screen and (${devices.mobileL}) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer {
      &--cont {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        flex: 2 1 40rem;
        /* background-color: orange; */
      }

      &__header {
        align-items: flex-start;
        flex: 1 1 25rem;
        /* background-color: brown; */
      }

      &__sec {
        /* text-align: left; */

        &--link {
        }
      }
    }
  }

  @media screen and (${devices.tablet}) {
    .footer {
      &--cont {
        justify-content: space-around;
      }
    }
  }

  @media screen and (${devices.laptop}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;
