import "./App.css";
import "./iframe.css";
import { Notifications } from "./components/Notifications/Index";
import { Pages } from "./routes";
// import { useState } from "react";
import {
  Normalize,
  VariablesStyle,
  BaseStyles,
  UtilitiesStyle,
  ButtonStyles,
} from "./styles/Index";
// import { useUpdatePubKey } from "./services/get-pub-key";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { GlobalDebug } from "./utils/helpers/remove-console";

function App() {
  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false, false);
  }, []);

  // useUpdatePubKey();
  return (
    <>
      {/* <Detector
        render={({ online }) => (
          <div className={online ? "normal" : "warning"}>
            You are currently {online ? "online" : "offline"}
          </div>
        )}
      /> */}
      {/* styles start */}
      <VariablesStyle />
      <Normalize />
      <BaseStyles />
      <ButtonStyles />
      <UtilitiesStyle />
      {/* styles end */}
      <Notifications />
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    </>
  );
}

export default App;
