import { createGlobalStyle } from "styled-components";

const Utilities = createGlobalStyle`
    .homeLogo {
        display: block;
        text-align: left;

        img {
            height: clamp(2rem, calc(2.5rem + 2.2vw), 3.2rem);
        }
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container {
        max-width: 150rem;
        margin-right: auto;
        margin-left: auto;
    }

    .ratings {
        background: var(--primary-3);
        border-radius: 18.3607rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: bold;
        width: max-content;
        font-size: 1.2rem;

        span {
            margin-right: 1rem;
        }
    }

    .avatar {
        &__text {
            border-radius: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            background: var(--info-1);
            color: var(--white);
        }

        &__img {
            width: 6.1rem;
            height: 6.1rem;
            overflow: hidden;
            border-radius: 50%;
            border: 0.4rem solid var(--white);
            padding: 0.8rem;
            -webkit-filter: drop-shadow(0px 0px 1px rgba(12,26,75,0.1))
                            drop-shadow(0px 10px 16px rgba(20,37,63,0.06));
            filter: drop-shadow(0px 0px 1px rgba(12,26,75,0.1))
                    drop-shadow(0px 10px 16px rgba(20,37,63,0.06));

            img {
                border-radius: 50%;
            } 
        }
    }

    .box {
        background: var(--white);
        box-shadow: var(--shadow-sm);
        border-radius: 1.5rem;
        padding: 2.4rem 3rem;

        &__heading {
            &--flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__header {
            color: var(--dark);
            letter-spacing: -0.005em;
            font-size: 1.8rem;
            margin-bottom: 1.6rem;
        }

        &__items {
            color: var(--muted);
            font-size: 1.4rem;

            &:not(:last-child) {
                margin-bottom: 1.9rem;
            }

            &--bold {
                font-weight: 600;
            }
        }
    }

    .aria-invisible {
      border: 0; 
      padding: 0;
      margin: -1px;
      margin: 0;
      clip: rect(0 0 0 0); 
      height: 1px;  
      width: 1px;
      overflow: hidden;
      position: absolute;
    }

    .MuiTooltip-tooltip {
        font-size: 1.2rem;
        padding: 0.2rem 0.6rem;
    }

    .MuiTooltip-arrow.css-kudwh-MuiTooltip-arrow {
        color: var(--gray-20);
        left: -0.5rem !important;
        height: 0.81em;
        width: 1.2em;
        font-size: 1.5rem;
    }


    .MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop.css-1k51tf5-MuiTooltip-tooltip {
        background-color: var(--white);
        background-color: var(--gray-20);
        color: var(--black);
        box-shadow: var(--shadow-sm);
    }

    /* modal styles */
    .modal {
        &__specialityChoice {
            .modal {
                &__header {
                    margin-bottom: 0 !important;
                }
            } 
        }
    }

    .hideScrollbarWidth {
        /* firefox */
        scrollbar-width: none;

        /* chrome and sefari */
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export default Utilities;
